import './App.css';
import {Card, Container, Grid, Input, Loading, Row, Text, Textarea} from "@nextui-org/react";
import {useState} from "react";
import {Configuration, OpenAIApi} from "openai";

const configuration = new Configuration({apiKey: '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'})
const openai = new OpenAIApi(configuration)

function App() {
    const [find, setFind] = useState('')
    const [recipe, setRecipe] = useState('')
    const [loading, setLoading] = useState(false)
    const findRecipe = async () => {
        setLoading(true)
        const response = await openai.createChatCompletion({
            model: 'gpt-3.5-turbo',
            messages: [{role: "user", content: 'dime la receta para hacer ' + find}]
        })
        setRecipe(response.data.choices[0].message.content)
        setLoading(false)
    }

    return (
        <div className={'container'}>
            <Container xl>
                <Grid.Container gap={5} justify={'center'}>
                    <Grid xs={12} justify={'center'}>
                        <Text h2 color={'white'} css={{
                            textGradient: "45deg, $purple600 -20%, $pink600 100%",
                        }}>Recetas de cocina</Text>
                    </Grid>
                    <Grid xs={12} sm={12} md={8} lg={8} xl={6} justify={'center'} alignItems={'center'}>
                        <Input css={{width: '100vw'}} contentRight={loading ? <Loading size="xs"/> : null} value={find}
                               onChange={value => setFind(value.target.value)} placeholder={'Busca una receta'}
                               clearable={!loading} size={"xl"} onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                findRecipe()
                            }
                        }}/>
                    </Grid>
                    <Row justify={'center'}>
                        <Grid xs={12} sm={12} md={8} lg={8} xl={6} justify={'center'}>
                            <Card>
                                <Card.Body>
                                    <Textarea value={recipe} maxRows={25} minRows={15}/>
                                </Card.Body>
                            </Card>
                        </Grid>
                    </Row>

                </Grid.Container>
            </Container>
        </div>

    );
}

export default App;
